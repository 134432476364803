




































import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { IOption, Pagination } from "@interface/common.interface";
import Vue from "vue";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { CodeTranslator } from "@interface/code-translator.interface";
import { codeTranslatorServices } from "@service/code-translator.service";

export default Vue.extend({
  name: "CSelectCuts",
  mixins: [
    MNotificationVue
  ],
  props: {
    value: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    propCutsCode: {
      type: String,
      default: undefined
    },
    forList: {
      type: Boolean,
      default: true
    }
  },
  data() {
    this.searchItem = debounceProcess(this.searchItem, 300);
    return {
      loading: false,
      dtOpt: [] as IOption<CodeTranslator>[],
      queryParams: {
        page: 0,
        limit: DEFAULT_PAGE_SIZE,
        search: "type~Cuts",
      },
      dtList: {} as Pagination<CodeTranslator[]>,
    };
  },
  watch: {
    propCutsCode(newVal) {
      if (newVal) {
        this.handleMissingOpts();
      }
    }
  },
  created() {
    this.getList(this.queryParams);
    this.handleMissingOpts();
  },
  methods: {
    getListCuts(params: RequestQueryParamsModel): Promise<Pagination<CodeTranslator[]>> {
      return codeTranslatorServices.getList(params);
    },
    onSelect(e: string, meta: CodeTranslator): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
    },
    searchItem(search = ""): void {
      const searchBy: string[] = ["type~Cuts"];
      let findBy = "";
      if (search) {
        searchBy.push(`code~*${search}*_OR_key~*${search}*`);
        findBy = searchBy.join("_AND_");
      } else {
        findBy = searchBy.join();
      }
      this.queryParams.page = 0;
      this.queryParams.search = findBy;
      this.dtOpt = [];
      this.getList(this.queryParams);
    },
    popupScroll(e): void {
      if ((this.dtList.totalPages - 1) === this.dtList.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListCuts(params);
        const { dtOpt } = this;
        const opts = res.data.map(x => ({ key: x.key, value: x.id, meta: x }));
        this.dtOpt = [...dtOpt, ...opts];
        this.dtList = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    async handleMissingOpts(): Promise<void> {
      try {
        if (this.forList || !this.value) return;
        const opt = this.dtOpt.find(x => x.value === this.value);
        if (!opt) {
          const { data } = await this.getListCuts({ search: `secureId~${this.value}` });
          const newOpt = { key: data[0].key, value: data[0].id, meta: data[0] };
          const { dtOpt } = this;
          this.dtOpt = [...dtOpt, newOpt];
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    clear(): void {
      this.$emit("input", undefined);
      this.$emit("on-clear", { value: "", meta: undefined });
    },
  }
});

