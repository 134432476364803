import { Api } from "@/models/class/api.class";
import { CodeTranslator, CreateCodeTranslator } from "@/models/interface-v2/code-translator.interface";
import { IGenericResponsePost, Pagination } from "@/models/interface-v2/common.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class CodeTranslatorServices extends HttpClient {
  constructor() {
    super();
  }

  public getList(params: RequestQueryParamsModel): Promise<Pagination<CodeTranslator[]>> {
    return this.get<Pagination<CodeTranslator[]>>(Api.CodeTranslator, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public create(payload: CreateCodeTranslator): Promise<IGenericResponsePost> {
    return this.post<IGenericResponsePost, CreateCodeTranslator>(Api.CodeTranslator, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public getDetail(id: string): Promise<CodeTranslator> {
    return this.get<CodeTranslator>(`${Api.CodeTranslator}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public update(id: string, payload: CreateCodeTranslator): Promise<IGenericResponsePost> {
    return this.put<IGenericResponsePost, CreateCodeTranslator>(`${Api.CodeTranslator}/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public deleteId(id: string): Promise<boolean> {
    return this.delete<boolean>(`${Api.CodeTranslator}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const codeTranslatorServices = new CodeTranslatorServices();
